<template>
  <v-container>
    <v-file-input
      v-model="files"
      label="Subir fotos"
      multiple
      prepend-icon="mdi-camera"
      accept="image/*"
      @change="handleFiles"
    ></v-file-input>

    <v-row>
      <draggable v-model="photos" class="row" :options="{ group: 'photos' }">
        <v-col v-for="(photo, index) in photos" :key="index" cols="12" sm="6" md="4">
          <v-img :src="photo.file" width="200px" aspect-ratio="1.75">
            {{ photo.name }}
            <v-btn fab x-small dark color="white" top right @click="removePhoto(index)">
              <v-icon color="red">mdi-delete</v-icon>
            </v-btn>
          </v-img>
        </v-col>
      </draggable>
    </v-row>
  </v-container>
</template>

<script>
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  data() {
    return {
      files: [],
      photos: [],
    };
  },
  methods: {
    handleFiles() {
      // let idx = 0;
      for (let file of this.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;
          img.onload = () => {
            let canvas = document.createElement("canvas");
            let ctx = canvas.getContext("2d");
            let width = img.width;
            let height = img.height;
            const minWidth = 200;

            if (width < minWidth) {
              const scaleFactor = minWidth / width;
              width = minWidth;
              height = height * scaleFactor;
            }

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            var imageUrl = canvas.toDataURL("image/webp");

            this.photos.push({
              name: file.name,
              file: imageUrl,
            });
          };
        };
        reader.readAsDataURL(file);
      }
      this.files = [];
    },
    removePhoto(index) {
      this.photos.splice(index, 1);
    },
  },
  watch: {
    photos: function () {
      console.log(this.photos);
    },
  },
};
</script>

<style scoped>
.draggable {
  display: flex;
  flex-wrap: wrap;
}
.v-card {
  transition: transform 0.3s;
}
.v-card:hover {
  transform: translateY(-5px);
}
.v-card-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
