<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800px"
      scrollable
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-form v-model="valid" ref="itemForm" @submit="item_submit">
        <v-card tile height="100%">
          <v-card-title class="pa-0">
            <v-app-bar tile dense>
              <v-tabs show-arrows v-model="tab">
                <v-tab key="product">
                  <v-icon left> mdi-food-apple-outline </v-icon>
                </v-tab>
                <v-tab key="note">
                  <v-icon> mdi-note-edit-outline </v-icon>
                </v-tab>
                <v-tab key="photo">
                  <v-icon> mdi-camera-plus-outline </v-icon>
                </v-tab>

                <v-tab key="raw_material">
                  <v-icon> mdi-format-list-numbered-rtl </v-icon>
                </v-tab>
              </v-tabs>
              <v-spacer></v-spacer>
              <v-btn icon color="success" @click="close()" class="ml-6">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-app-bar>
          </v-card-title>
          <v-card-text class="pa-2">
            <v-tabs-items v-model="tab">
              <v-tab-item key="producto">
                <v-row class="pa-2">
                  <v-col cols="6" md="4">
                    <v-combobox
                      v-model="item.category"
                      id="category"
                      hide-details="auto"
                      label="Categoría"
                      :items="categories"
                      :rules="f_required"
                      outlined
                      required
                      dense
                    />
                  </v-col>
                  <v-col cols="6" md="4">
                    <v-combobox
                      v-model="item.subcategory"
                      id="subcategory"
                      hide-details="auto"
                      label="Sub-categoria"
                      :items="subcategories"
                      :rules="f_required"
                      outlined
                      required
                      dense
                    />
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-select
                      v-model="item.supplier"
                      hide-details="auto"
                      item-text="name"
                      item-value="code"
                      :items="suppliers"
                      label="Proveedor"
                      outlined
                      dense
                    />
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="item.product_name"
                      id="product_name"
                      hide-details="auto"
                      label="Nombre"
                      outlined
                      required
                      dense
                    />
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-combobox
                      v-model="item.brand"
                      id="brand"
                      hide-details="auto"
                      label="Marca"
                      :items="brands"
                      :rules="f_required"
                      outlined
                      required
                      dense
                      @keyup="returnCap"
                    />
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-text-field
                      v-model="item.reference"
                      id="reference"
                      hide-details="auto"
                      label="Referencia"
                      :rules="f_required"
                      outlined
                      required
                      dense
                      @keyup="returnCap"
                    />
                  </v-col>
                  <v-col cols="3" md="3">
                    <v-text-field
                      class="centered-input"
                      hide-details="auto"
                      :rules="f_required"
                      v-model="item.size"
                      @keypress="onlyNumber"
                      @keyup="costTaxChange"
                      label="Tamaño"
                      outlined
                      required
                      dense
                    />
                  </v-col>
                  <v-col cols="6" md="3">
                    <v-select
                      v-model="item.uom"
                      hide-details="auto"
                      :rules="f_required"
                      item-text="name"
                      item-value="code"
                      :items="setting['unit_measures']"
                      label="UOM"
                      outlined
                      @change="costTaxChange"
                      dense
                    />
                  </v-col>
                  <v-col cols="3" md="3">
                    <v-text-field
                      v-model="item.pack"
                      label="Pack"
                      class="centered-input"
                      hide-details="auto"
                      @keypress="onlyNumber"
                      @keyup="costTaxChange"
                      outlined
                      required
                      dense
                    />
                  </v-col>
                  <v-col cols="4" md="3">
                    <v-text-field
                      v-model="item.health_tax"
                      label="Imp. Saludable"
                      class="centered-input"
                      hide-details="auto"
                      @keyup="costTaxChange"
                      type="number"
                      prepend-inner-icon="mdi-currency-usd"
                      outlined
                      required
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4" md="4">
                    <v-text-field
                      v-model="item.tax"
                      label="IVA"
                      class="centered-input"
                      hide-details="auto"
                      @keyup="costTaxChange"
                      append-icon="mdi-percent"
                      outlined
                      required
                      dense
                    />
                  </v-col>

                  <v-col cols="4" md="4" v-if="$vuetify.breakpoint.xsOnly">
                    <v-text-field
                      v-model="item.utility"
                      label="Margen"
                      class="centered-input"
                      hide-details="auto"
                      @keyup="priceCalculator"
                      type="number"
                      append-icon="mdi-percent"
                      outlined
                      required
                      dense
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="6" md="4">
                    <v-text-field
                      v-model="cost"
                      label="Costo sin IVA"
                      class="centered-input"
                      hide-details="auto"
                      @keyup="costChange"
                      prepend-inner-icon="mdi-currency-usd"
                      outlined
                      required
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" md="4">
                    <v-text-field
                      v-model="costTax"
                      label="Costo con IVA"
                      class="centered-input"
                      hide-details="auto"
                      @keyup="costTaxChange"
                      prepend-inner-icon="mdi-currency-usd"
                      outlined
                      required
                      dense
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="4" md="4" v-if="!$vuetify.breakpoint.xsOnly">
                    <v-text-field
                      v-model="item.utility"
                      label="Margen"
                      class="centered-input"
                      hide-details="auto"
                      @keyup="priceCalculator"
                      type="number"
                      append-icon="mdi-percent"
                      outlined
                      required
                      dense
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="6" md="4">
                    <v-text-field
                      v-model="price"
                      label="Precio sin IVA"
                      class="centered-input"
                      hide-details="auto"
                      @keyup="priceChange"
                      prepend-inner-icon="mdi-currency-usd"
                      outlined
                      required
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" md="4">
                    <v-text-field
                      v-model="priceTax"
                      label="Precio con IVA"
                      class="centered-input"
                      hide-details="auto"
                      @keyup="priceTaxChange"
                      prepend-inner-icon="mdi-currency-usd"
                      outlined
                      required
                      dense
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="item.sku"
                      hide-details="auto"
                      label="SKU/EAN/CODIGO"
                      append-icon="mdi-barcode-scan"
                      outlined
                      required
                      dense
                    />
                  </v-col>
                  <v-col cols="6" md="4">
                    <v-select
                      v-model="item.shipping_time"
                      hide-details="auto"
                      label="Tiempo de entrega"
                      :items="shipping_time"
                      item-text="text"
                      item-value="value"
                      append-icon="mdi-truck-delivery"
                      outlined
                      dense
                    />
                  </v-col>
                  <v-col cols="6" md="4">
                    <v-select
                      v-model="item.active"
                      hide-details="auto"
                      :rules="f_required"
                      item-text="text"
                      item-value="value"
                      :items="active_lst"
                      label="Status"
                      outlined
                      dense
                    />
                  </v-col>

                  <v-col cols="6" md="3">
                    <v-text-field
                      v-model="item.cost_uom"
                      hide-details="auto"
                      label="Costo por Unidad"
                      class="centered-input"
                      outlined
                      required
                      dense
                      readonly
                      :suffix="item.normalized_uom"
                    />
                  </v-col>
                  <v-col cols="6" md="3">
                    <v-text-field
                      v-model="item.price_uom"
                      hide-details="auto"
                      label="Precio por Unidad"
                      class="centered-input"
                      outlined
                      required
                      dense
                      readonly
                      :suffix="item.normalized_uom"
                    />
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item key="note">
                <v-row>
                  <v-col cols="12" md="12" class="vueEditorCSS">
                    <vue-editor
                      v-model="item.description"
                      :editorToolbar="customToolbar"
                    ></vue-editor>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item key="photo" align="center" justify="center">
                <v-switch
                  v-model="item.main"
                  label="Destacado"
                  false-value="0"
                  true-value="1"
                  class="ml-3"
                />
                <PhotoUpload></PhotoUpload>
              </v-tab-item>
              <v-tab-item key="label" align="center" justify="center"> </v-tab-item>
              <v-tab-item key="raw_material" align="center" justify="center">
                <v-data-table
                  :headers="headers_cart[$vuetify.breakpoint.xsOnly]"
                  :items="raw_material_list"
                  :items-per-page="-1"
                  class="elevation-0"
                  mobile-breakpoint="0"
                  item-key="ean"
                  loader-height="2"
                  loading-text="Cargando ..."
                  hide-default-footer
                >
                  <template v-slot:top>
                    <v-toolbar tile dense>
                      Lista de productos
                      <v-spacer></v-spacer>
                      <v-btn
                        x-small
                        fab
                        dark
                        color="indigo"
                        @click="dialog_addItm = !dialog_addItm"
                      >
                        <v-icon dark> mdi-plus </v-icon>
                      </v-btn>
                    </v-toolbar>
                  </template>
                  <!-- <template v-slot:[`item.product_name`]="{ item }">
                    <span>
                      {{ item.product_name }}
                      <br />
                      <span v-if="item.note" v-text="item.note" />
                      <small v-if="item.shipping_time > 1">
                        Tiempo de entrega: {{ item.shipping_time }} días hábiles.
                      </small>
                    </span>
                  </template> -->
                  <!-- <template v-slot:[`item.qty`]="{ item }">
                    <span>
                      {{ item.qty }}
                    </span>
                  </template>
                  <template v-slot:[`item.size`]="{ item }">
                    <span>
                      {{ item.size + " " + item.uom }}
                    </span>
                  </template>
                  <template v-slot:[`item.tax_base`]="{ item }">
                    {{ parseFloat(item.tax_base).toLocaleString(2) }}
                  </template>
                  <template v-slot:[`item.tax_value`]="{ item }">
                    {{ parseFloat(item.tax_value).toLocaleString(2) }}
                  </template>
                  <template v-slot:[`item.tItem`]="{ item }">
                    <span>
                      {{ parseFloat(item.tItem).toLocaleString(2) }}
                      <br />
                      <small
                        class="red--text text-decoration-line-through"
                        v-if="item.discount > 0"
                      >
                        {{ (item.price * item.qty * item.pack).toLocaleString(2) }}
                      </small>
                    </span>
                  </template>
                  -->
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn text color="primary" @click="editItm(item)">
                      <v-icon>mdi-file-edit-outline</v-icon>
                    </v-btn>
                  </template>
                  <template slot="body.append">
                    <tr>
                      <th>Totales</th>

                      <th class="text-center">
                        {{ sumField(raw_material_list, "qty").toLocaleString(2) }}
                      </th>
                      <th class="text-center"></th>
                      <th class="text-center"></th>
                      <th v-if="!$vuetify.breakpoint.xsOnly" class="text-right">
                        {{ sumField(raw_material_list, "cost").toLocaleString(2) }}
                      </th>
                    </tr>
                  </template>
                </v-data-table>
                <v-dialog
                  persistent
                  v-model="dialog_addItm"
                  max-width="600px"
                  :scrollable="$vuetify.breakpoint.xsOnly"
                  :fullscreen="$vuetify.breakpoint.xsOnly"
                >
                  <v-form v-model="valid" ref="itmForm" @submit="add_raw_material">
                    <v-card>
                      <v-card-title>
                        Producto compuesto
                        <v-spacer></v-spacer>
                        <v-btn small text icon @click="dialog_addItm = !dialog_addItm">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-text class="pt-6">
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-combobox
                              v-model="itmSelected"
                              return-object
                              hide-details="auto"
                              label="Productos"
                              :items="catalog"
                              item-text="product_name"
                              outlined
                              required
                              dense
                              :rules="f_required"
                            />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model="itmSelected.qty"
                              :rules="f_required"
                              class="center-input"
                              hide-details
                              filled
                              rounded
                              Label="Qty"
                              dense
                              single-line
                              :suffix="itmSelected.normalized_uom"
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn small text color="danger" type="button" @click="deleteItm">
                          Delete
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn small text color="success" type="submit"> Add </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-dialog>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="delete_itm(item)" v-if="!newItem">
              <span>Borrar</span>
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn :disabled="!valid" type="submit">
              <span>Guardar</span>
              <v-icon>mdi-content-save-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
      <!-- </v-dialog> -->
    </v-dialog>
    <v-dialog persistent max-width="290" v-model="dialog_confirm">
      <v-card>
        <v-card-title> Confirmar </v-card-title>
        <v-card-text> Desea eliminar el registro? </v-card-text>
        <v-card-actions>
          <v-btn
            large
            text
            color="red"
            type="button"
            @click="dialog_confirm = !dialog_confirm"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn large text color="success" type="button" @click="delete_item">
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="alerts" hide-overlay persistent>
      <v-card>
        <v-alert border="left" color="orange" prominent text type="warning">
          <v-row align="center">
            <v-col class="grow"> Formato de archivo o tamaño no valido. </v-col>
            <v-col class="shrink">
              <v-btn fab x-small @click="alerts = false">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import settings from "../utils/settings.js";
import { webserver } from "../services/webserver.js";
import createItem from "../utils/createItem.js";
import { VueEditor } from "vue2-editor";
import PhotoUpload from "../components/PhotoUpload.vue";

export default {
  name: "ProductForm",
  components: { VueEditor, PhotoUpload },
  data() {
    return {
      setting: settings(),
      dialog_addItm: false,
      raw_material_list: [],
      itmSelected: createItem(),
      distributors: [],
      shipping_time: [
        { text: "1 día", value: "1" },
        { text: "2 días", value: "2" },
        { text: "3 días", value: "3" },
        { text: "4 días", value: "4" },
        { text: "Mismo día", value: "0" },
      ],
      headers_cart: {
        false: [
          {
            text: "Description",
            align: "start",
            sortable: true,
            value: "product_name",
            dataType: "text",
            width: "40%",
          },

          {
            text: "Qty",
            align: "center",
            sortable: true,
            value: "qty",
            dataType: "text",
          },
          {
            text: "UOM",
            align: "center",
            sortable: true,
            value: "normalized_uom",
            dataType: "text",
          },
          {
            text: "UOM Cost",
            align: "end",
            sortable: true,
            value: "cost_uom",
            dataType: "number",
          },
          {
            text: "Costo",
            align: "end",
            sortable: true,
            value: "cost",
            dataType: "number",
          },

          { text: "", value: "actions", sortable: false },
        ],
        true: [
          {
            text: "Description",
            align: "start",
            sortable: true,
            value: "product_name",
            dataType: "text",
          },
          {
            text: "Qty",
            align: "center",
            sortable: true,
            value: "qty",
            dataType: "number",
            width: "5",
          },
          {
            text: "Cost",
            align: "end",
            sortable: true,
            value: "cost",
            dataType: "number",
          },
          { text: "", value: "actions", sortable: false },
        ],
      },
      alerts: false,
      loading_status: false,
      tab: null,
      dialog_confirm: false,
      compressed_image: null,
      compressed_label: null,
      reg_delete: null,
      valid: false,
      customToolbar: [
        // [{ font: [] }],
        // [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        //  [{ header: 1 }, { header: 2 }],
        //[{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
      ],
      active_lst: [
        { value: "1", text: "Activo" },
        { value: "0", text: "InActivo" },
      ],
      uom_lst: [
        { value: "UN", text: "Unidad" },
        { value: "GR", text: "Gramo" },
        { value: "KG", text: "Kilogramo" },
        { value: "LB", text: "Libra" },
        { value: "LT", text: "Litro" },
        { value: "ML", text: "Mililitro" },
        { value: "GL", text: "Galon" },
        { value: "CJ", text: "Caja" },
        { value: "OZ", text: "Onza" },
        { value: "Mes", text: "Mes" },
        { value: "Año", text: "Año" },
        { value: "Día", text: "Día" },
      ],
      f_required: [(v) => !!v || "Requerido"],
      f_email: [
        (v) => !!v || "Correo valido",
        (v) => /.+@.+/.test(v) || "Correo no valido",
      ],
      codeRules: [
        (v) => !!v || "El código es requerido",
        (v) => v.length === 4 || "EL código debe tener 4 caracteres.",
      ],
      productPhoto: null,
      chosenPhoto: null,
      labelPhoto: null,
      cost: 0,
      costTax: 0,
      price: 0,
      priceTax: 0,
      utility: 0,
    };
  },
  props: {
    table: {
      type: String,
      required: true,
    },
    store: {
      type: String,
      default: "",
      required: true,
    },
    item: {
      type: Object,
      required: true,
      default: createItem(),
    },
    catalog: {
      type: Array,
      required: false,
    },
    categories: {
      type: Array,
      required: false,
    },
    subcategories: {
      type: Array,
      required: false,
    },
    brands: {
      type: Array,
      required: false,
    },
    suppliers: {
      type: Array,
      required: false,
    },
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    newItem: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  mounted() {},
  methods: {
    add_raw_material(e) {
      e.preventDefault();
      var check = this.$refs.itmForm.validate();
      if (check) {
        this.itmSelected.cost =
          parseFloat(this.itmSelected.qty) * parseFloat(this.itmSelected.cost_uom);
        // Find the index of an object with a specific property value
        let index = this.raw_material_list.findIndex(
          (item) => item.code === this.itmSelected.code
        );
        // Check if the object with the specified property value exists in the array
        if (index === -1) {
          // If not found, push a new object with the desired properties
          this.raw_material_list.push(this.itmSelected);
        }
        this.subItemsSum();
      }
    },
    subItemsSum() {
      this.costTax = 0;
      this.item.size = 0;
      this.raw_material_list.forEach((itm) => {
        this.costTax += parseFloat(itm.cost);
        this.item.size += parseFloat(itm.qty);
      });
      var tax_value = parseFloat(this.item.tax) / 100 + 1;
      this.cost = (
        (parseFloat(this.costTax) - parseFloat(this.item.health_tax)) /
        parseFloat(tax_value)
      ).toFixed(2);
      this.item.utility = "0";
      this.price = (
        parseFloat(this.costTax) *
        (parseFloat(this.item.utility) / 100 + 1)
      ).toFixed(2);
      this.priceTax = (parseFloat(this.price) * parseFloat(tax_value)).toFixed(2);
      this.item.product_options = JSON.stringify(this.raw_material_list);
      this.dialog_addItm = false;
    },
    editItm(e) {
      this.itmSelected = e;
      this.dialog_addItm = true;
    },
    deleteItm() {
      let index = this.raw_material_list.findIndex(
        (item) => item.code === this.itmSelected.code
      );
      if (index !== -1) {
        this.raw_material_list.splice(index, 1);
      }
      this.subItemsSum();
    },
    sumField(fld, key) {
      var dta = fld;
      return dta.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },
    sumFieldPrice(fld, key) {
      var dta = fld;
      return dta.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },
    PhotoToBase64() {
      if (!this.chosenPhoto) {
        this.data = "No File Chosen";
      }
      this.getBase64(this.chosenPhoto).then((data) => {
        this.compressed_image = data;
        this.productPhoto = data;
      });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    handleFiles() {
      let i = 0;
      for (let file of this.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;
          img.onload = () => {
            let canvas = document.createElement("canvas");
            let ctx = canvas.getContext("2d");
            let width = img.width;
            let height = img.height;
            const minWidth = 200;

            if (width < minWidth) {
              const scaleFactor = minWidth / width;
              width = minWidth;
              height = height * scaleFactor;
            }

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            console.log("canvas", file.id);
            var imageUrl = canvas.toDataURL("image/webp");

            this.photos.push({
              name: this.item.code + "_" + i,
              file: imageUrl,
            });
            i++;
          };
        };
        reader.readAsDataURL(file);
      }
      this.files = [];
    },
    removePhoto(index) {
      this.photos.splice(index, 1);
    },
    returnCap(event) {
      this.item[event.target.id] = event.target.value.toUpperCase();
    },
    onlyNumber($event) {
      var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    costChange($event) {
      var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
      var tax_value = this.item.tax / 100 + 1;

      this.costTax = (
        parseFloat(this.cost) * parseFloat(tax_value) +
        parseFloat(this.item.health_tax)
      ).toFixed(2);
      this.price = (
        parseFloat(this.costTax) *
        (parseFloat(this.item.utility) / 100 + 1)
      ).toFixed(2);
      this.priceTax = (parseFloat(this.price) * parseFloat(tax_value)).toFixed(2);
      // var utitem = (parseFloat(this.price) - parseFloat(this.cost)).toFixed(2);
      // this.item.utility = ((parseFloat(utitem) / parseFloat(this.price)) * 100).toFixed(
      //   2
      // );
    },
    costTaxChange($event) {
      var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
      var tax_value = parseFloat(this.item.tax) / 100 + 1;

      this.cost = (
        (parseFloat(this.costTax) - parseFloat(this.item.health_tax)) /
        parseFloat(tax_value)
      ).toFixed(2);

      this.price = (
        parseFloat(this.costTax) *
        (parseFloat(this.item.utility) / 100 + 1)
      ).toFixed(2);
      this.priceTax = (parseFloat(this.price) * parseFloat(tax_value)).toFixed(2);

      var normalizer = this.normalizeItem();
      this.item.cost_uom = (
        parseFloat(this.costTax) /
        (normalizer * parseFloat(this.item.size)) /
        parseFloat(this.item.pack)
      ).toFixed(2);
      this.item.price_uom = (
        parseFloat(this.priceTax) /
        (normalizer * parseFloat(this.item.size)) /
        parseFloat(this.item.pack)
      ).toFixed(2);
    },
    priceTaxChange($event) {
      var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }

      this.item.price_uom = 0;

      var tax_value = this.item.tax / 100 + 1;
      this.price = (parseFloat(this.priceTax) / parseFloat(tax_value)).toFixed(2);

      var utitem = (parseFloat(this.price) - parseFloat(this.cost)).toFixed(2);
      this.item.utility = ((parseFloat(utitem) / parseFloat(this.price)) * 100).toFixed(
        2
      );
      var normalizer = this.normalizeItem();
      this.item.price_uom = (
        parseFloat(this.priceTax) /
        (normalizer * parseFloat(this.item.size)) /
        parseFloat(this.item.pack)
      ).toFixed(2);
    },
    priceCalculator() {
      var utility_value = this.item.utility / 100 + 1;
      var tax_value = this.item.tax / 100 + 1;
      this.price = (parseFloat(this.costTax) * parseFloat(utility_value)).toFixed(2);
      this.priceTax = (parseFloat(this.price) * parseFloat(tax_value)).toFixed(2);
      var normalizer = this.normalizeItem();
      this.item.price_uom = (
        parseFloat(this.priceTax) /
        (normalizer * parseFloat(this.item.size)) /
        parseFloat(this.item.pack)
      ).toFixed(2);
    },

    normalizeItem() {
      var normalizer = 1;
      switch (this.item.uom) {
        case "LB":
          normalizer = 453;
          this.item.normalized_uom = "GR";
          break;
        case "LT":
          normalizer = 1000;
          this.item.normalized_uom = "ML";
          break;
        case "KG":
          normalizer = 1000;
          this.item.normalized_uom = "GR";
          break;
        case "OZ":
          normalizer = 28.34;
          this.item.normalized_uom = "GR";
          break;
        case "GL":
          normalizer = 3785.4;
          this.item.normalized_uom = "ML";
          break;
        case "ML":
          normalizer = 1;
          this.item.normalized_uom = "ML";
          break;
        case "UN":
          normalizer = 1;
          this.item.normalized_uom = "UN";
          break;
        case "CJ":
          normalizer = 1;
          this.item.normalized_uom = "UN";
          break;
        case "GR":
          normalizer = 1;
          this.item.normalized_uom = "GR";
          break;
        case "Mes":
          normalizer = 1;
          this.item.normalized_uom = "Mes";
          break;
        case "Año":
          normalizer = 1;
          this.item.normalized_uom = "Año";
          break;
        case "Día":
          normalizer = 1;
          this.item.normalized_uom = "Día";
          break;
        default:
          normalizer = 1;
          this.item.normalized_uom = "UN";
      }
      this.item.normalized_size = parseFloat(this.item.size) * normalizer;
      return normalizer;
    },

    // priceCalculator() {
    //   var porcent = (100 - this.item.utility) / 100;
    //   this.price = (parseFloat(this.cost) / porcent).toFixed(2);
    //   var tax_value = this.item.tax / 100 + 1;
    //   this.priceTax = (parseFloat(this.price) * parseFloat(tax_value)).toFixed(2);
    // },
    priceChange($event) {
      var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
      var tax_value = this.item.tax / 100 + 1;
      this.priceTax = parseFloat(this.price) * tax_value;
      var utitem = (parseFloat(this.priceTax) - parseFloat(this.costTax)).toFixed(2);
      this.item.utility = (
        (parseFloat(utitem) / parseFloat(this.priceTax)) *
        100
      ).toFixed(2);
    },

    getImagePhoto: function (account, store, src) {
      var url = "https://" + this.$store.getters.getHost + "/" + account + "/" + src;
      if (this.compressed_image) url = this.compressed_image;
      return url;
    },
    chooseImage() {
      this.$refs.fileInput.click();
    },
    chooseLabel() {
      this.$refs.labelInput.click();
    },
    item_submit(e) {
      e.preventDefault();
      this.valid = this.$refs.itemForm.validate();
      if (this.valid) {
        this.item.cost = this.costTax;

        this.item.store = this.store;

        this.item.tax_base = parseFloat(this.price);
        this.item.tax_value = parseFloat(this.priceTax) - parseFloat(this.price);

        this.item.price = this.priceTax;

        this.item.price_amount = parseFloat(this.price);
        this.item.price_value = parseFloat(this.priceTax);

        var qry = {
          account: this.$store.state.profile.account,
          table: this.table,
          data: [this.item],
        };
        if (this.compressed_image) {
          qry.photo = this.compressed_image;
        }
        if (this.compressed_label) {
          qry.label = this.compressed_label;
        }
        //console.log(qry);
        webserver("put_table", qry, () => {
          this.productPhoto = null;
          this.labelPhoto = null;
          this.loading_status = false;
          this.$emit("refresh", true);
        });
      }
    },
    delete_itm(data) {
      this.reg_delete = data.code;
      this.dialog_confirm = true;
    },
    delete_item() {
      this.loading_status = true;
      var qry = {
        table: this.table,
        filters: {
          account: this.item.account,
          store: this.item.store,
          code: this.item.code,
        },
      };
      webserver("delete_record", qry, () => {
        this.dialog_confirm = false;
        this.loading_status = false;
        this.$emit("refresh", true);
      });
    },
    close() {
      this.$emit("close", true);
    },
  },
  watch: {
    dialog: function () {
      // if (!e.picture) {
      //   this.compressed_image = null;
      // }
      ////console.log(e);
    },
    suppliers: function (e) {
      console.log("suppliers", e);
    },
    item: function (e) {
      this.item.supplier = this.suppliers.find(
        (prv) => prv.code.toUpperCase() === this.item.supplier.toUpperCase()
      );
      this.costTax = parseFloat(this.item.cost).toFixed(2);
      this.priceTax = parseFloat(this.item.price).toFixed(2);

      var tax_value = parseFloat(this.item.tax) / 100 + 1;

      this.cost = (
        (parseFloat(this.costTax) - parseFloat(this.item.health_tax)) /
        parseFloat(tax_value)
      ).toFixed(2);

      this.price = (
        parseFloat(this.costTax) *
        (parseFloat(this.item.utility) / 100 + 1)
      ).toFixed(2);

      this.compressed_image = null;
      if (e.picture) {
        this.productPhoto = this.getImagePhoto(e.account, "000000", e.picture);
      } else {
        this.productPhoto = null;
      }
      if (e.label) {
        this.labelPhoto = this.getImagePhoto(e.account, "000000", e.label);
      } else {
        this.labelPhoto = null;
      }
      if (this.item.product_options) {
        this.raw_material_list = JSON.parse(this.item.product_options);
      }
    },
  },
};
</script>

<style>
.btn-upload {
  top: 100px;
}

.bar-top {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 50;
}

.base-image-input {
  display: block;
  width: 100%;
  height: 250px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

.placeholder:hover {
  background: #e0e0e0;
}

.file-input {
  display: none;
}
</style>
